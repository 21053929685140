import React, { useState } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import Modal from './Modal';

const MobileNav = styled.nav`
    position: fixed;
    top: 4.5rem;
    left: 2rem;
    z-index: 99;
    display: none;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: var(--color-primary);
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    @media only screen and (max-width: 26.5625em) {
        display: flex;
    }
`

export default (props) => {

    const navColor = props.navColor ? props.navColor : 'gray'
    const [modalOpen,setmodalOpen] = useState(false);

    return (
        <div style={{ display: 'flex' }}>
            <Helmet>
				<title>Columna | Promoción de la Cultura y el Arte</title>
                <meta name="description" content="" />
                <link href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css" rel="stylesheet" />
            </Helmet>
            <MobileNav onClick={() => setmodalOpen(!modalOpen)}>
                <i className="material-icons">{modalOpen === false ? 'menu' : 'close'}</i>
            </MobileNav>
            <div css={css`
                position: relative;
                @media only screen and (max-width: 26.5625em) {
                    > header {
                        display: none;
                    }
                }
            `} className="layout-content">
                <header className="desktop">
                    <ol type="a" className={`header__container ${navColor}`}>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/projects">Projects</Link></li>
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/gallery">Gallery</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ol>
                </header>
                {props.children}
                <footer>
                    <div className="footer__container"></div>
                </footer>
            </div>
            <Modal isOpen={modalOpen} title="Navegación" closeModal={() => setmodalOpen(false)}>
                <ul css={css`
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    max-width: 40rem;
                    list-style: none;
                    li {
                        display: flex;
                        font-size: 1.4rem;
                        border-bottom: var(--line);
                        a {
                            padding: 2rem;
                            text-decoration: none;
                            color: var(--color-primary);
                            width: 100%;
                            font-weight: 700;
                            :hover {
                                background-color:var(--color-primary);
                                color: #fff;
                            }
                        }
                    }
                `}>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/projects">Projects</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/gallery">Gallery</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </Modal>
        </div>
    )
}