import React from 'react';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        zIndex: 9,
        backgroundColor: 'rgba(0,0,0,.9)'
    },
    content : {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        maxWidth: '40rem',
        border: 'none',
        backgroundColor: '#fff',
        boxShadow: '0 2rem 5rem rgba(0, 0, 0, 0.1)',
        padding: 0,
    }
};

export default function Mod ({ isOpen, closeModal, title, children }) {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel={title}
            ariaHideApp={false}
        >
            {children}
        </Modal>
    )
}